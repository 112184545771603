'use strict';

let INSTAW = INSTAW || {};
let UTILS = UTILS || {};

INSTAW.utils = UTILS || {};
INSTAW.utils = UTILS || {};
INSTAW.position = $(window).scrollTop();
INSTAW.offsetY = window.pageYOffset;

INSTAW.utils.inputLabels = function() {
    function placeholderPolyfill(el) {
      this.classList[this.value ? 'add' : 'remove']('filled');
    }

    if ( document.querySelector('input') ) {
        var allInputs = document.querySelectorAll('input');
        var allTextareas = document.querySelectorAll('textarea');

        [].forEach.call(allInputs, function(singleInput) {
            singleInput.classList[singleInput.value ? 'add' : 'remove']('filled');
            singleInput.addEventListener('change', placeholderPolyfill);
            singleInput.addEventListener('keyup', placeholderPolyfill);
        });

        [].forEach.call(allTextareas, function(singleTextarea) {
            singleTextarea.classList[singleTextarea.value ? 'add' : 'remove']('filled');
            singleTextarea.addEventListener('change', placeholderPolyfill);
            singleTextarea.addEventListener('keyup', placeholderPolyfill);
        });
    }
};

INSTAW.menu = {
    navbarPosition: function() {
        let scroll;
        
        if (document.querySelector('.js-navbar-scroll') && !document.querySelector('body').classList.contains('stop-scrolling') ) {
            scroll = window.scrollY  || window.pageYOffset  || document.documentElement.scrollTop;
    
            if ( document.querySelector('.js-navbar-scroll') ) {
                if (scroll > 200) {
                    document.querySelector('.js-navbar-scroll').classList.add('l-navbar--minimal');
                } else {
                        document.querySelector('.js-navbar-scroll').classList.remove('l-navbar--minimal');
                }
            }
    
            INSTAW.position = scroll;
        }
    }
};

INSTAW.stickyBlocks = function() {

};

INSTAW.stickyToTop = {
    blocks: function() {
        let stickyBlock = $('.js-sticky-block');

        if (!stickyBlock.length) {
            return;
        }
    
        stickyBlock
            .stick_in_parent({
                parent: '.js-sticky-block-parent', 
                offset_top: 100
            });
    },
    init: function() {
        this.blocks();
    }
};

INSTAW.utils.disableBodyScrolling = function() {
    INSTAW.offsetY = window.pageYOffset;
    $('body').css({top: -INSTAW.offsetY + 'px'});
    $('body').addClass('stop-scrolling');
};

INSTAW.utils.enableBodyScrolling = function() {
    $('body').removeClass('stop-scrolling');
    $(window).scrollTop(INSTAW.offsetY);
};

INSTAW.mobileMenu = {
    layer: $('.l-main__left'),
    backdrop: $('.backdrop'),
    showBackdrop: function() {
        this.backdrop.addClass('backdrop--opened');
    },
    hideBackdrop: function() {
        this.backdrop.removeClass('backdrop--opened');
    },
    openMenu: function() {
        INSTAW.utils.disableBodyScrolling();
        this.showBackdrop();

        setTimeout(() => {
            this.layer.fadeIn();
            this.layer.addClass('l-main__left--opened');
        }, 300);
    },
    closeMenu: function() {
        this.layer.fadeOut();

        setTimeout(() => {
            this.hideBackdrop();
            this.layer.removeClass('l-main__left-opened');
            INSTAW.utils.enableBodyScrolling();
        }, 300);
    },
};

INSTAW.utils.copyToClipboard = function(string) {
    // Create new element
    var el = document.createElement('textarea');
    // Set value (string to be copied)
    el.value = string;
    // Set non-editable to avoid focus and move outside of view
    el.setAttribute('readonly', '');
    el.style = {position: 'absolute', left: '-9999px'};
    document.body.appendChild(el);
    // Select text inside element
    el.select();
    // Copy text to clipboard
    document.execCommand('copy');
    // Remove temporary element
    document.body.removeChild(el);
};

INSTAW.copyText = function() {
    new ClipboardJS('.js-copy-text', {
        text: function(trigger) {
            let card = $(trigger).closest('.card');
            let text = $.trim( card.find('.js-text-to-be-copied').text() );

            card.addClass('card--show-overlay');

            setTimeout(() => {
                card.removeClass('card--show-overlay');
            }, 5000);

            return text;
        }
    });

    $('.js-close-card-overlay').on('click', function() {
        $(this).closest('.card').removeClass('card--show-overlay');
    });
};

// Cookie
INSTAW.utils.setCookie = function setCookie(name, val, days) {
    var expires;
    if (days) {
        var data = new Date();
        data.setTime(data.getTime() + (days * 24*60*60*1000));
        expires = "; expires="+data.toGMTString();
    } else {
        expires = "";
    }
    document.cookie = name + "=" + val + expires + "; path=/";
};

INSTAW.utils.isCookieSet = function(name) {
    if (document.cookie!=='') {
        var cookies=document.cookie.split("; ");

        for (var i=0; i<cookies.length; i++) {
            var cookieName=cookies[i].split("=")[0];
            var cookieVal=cookies[i].split("=")[1];

            if (cookieName===name) {
                return decodeURI(cookieVal);
            }
        }
    }
}

INSTAW.cookieNotification = {
    cookieName: 'cookieNotification',
    button: document.querySelector('.js-cookie-btn'),
    layer: document.querySelector('.js-cookie'),
    show: function() {

        if (!(INSTAW.utils.isCookieSet( this.cookieName ))) {
            this.layer.classList.add('cookie--show');
        }
    },
    save: function() {
        INSTAW.utils.setCookie( this.cookieName, true, 30 );
        this.layer.classList.remove('cookie--show');
        return false;
    },
    init: function() {
        if (this.layer) {
            this.show();
        }
    }
};

INSTAW.voting = function() {
    function vote({pageId, type}) {
        let trigger = $('.js-vote-' + type + '[data-id="' + pageId + '"]');
        let voteCounterWrapper = trigger.parent().parent();
        let voteCounter = voteCounterWrapper.find('.js-vote-counter');
        let totalVotes = voteCounterWrapper.find('.js-total-votes-counter');

        $('button[data-id="' + pageId + '"]').attr('disabled', true);

        $.ajax({
            url: '/ajax/voteForWishes',
            type: 'POST',
            data: 'pageId=' + pageId + '&type=' + type,
            success: function(response) {
                voteCounter.removeClass('color-green').removeClass('color-red');
                if (response.vote_counter > -1) {
                    voteCounter.addClass('color-green');
                } else {
                    voteCounter.addClass('color-red');
                }

                setTimeout(() => {
                    voteCounter.html(response.vote_counter);
                    totalVotes.html(response.total_votes);
                }, 0);

                //document.cookie = 'wishes-voted-' + pageId + '=voted; path=/';
            },
            error: function(response) {
                console.error(response);
            }
        });
    }

    document.querySelectorAll('.js-vote-plus').forEach(function(el) {
        el.addEventListener('click', function(event) {
            event.stopPropagation();
            event.preventDefault();

            let pageId = this.dataset.id;
            vote({
                pageId,
                type: 'plus'
            });
        });
    });

    document.querySelectorAll('.js-vote-minus').forEach(function(el) {
        el.addEventListener('click', function(event) {
            event.stopPropagation();
            event.preventDefault();

            let pageId = this.dataset.id;
            vote({
                pageId,
                type: 'minus'
            });
        });
    });
};

function sendCWVToGTM(data) {
    var gtmDataLayer = window.dataLayer || [];

    gtmDataLayer.push({
        event: 'web-vitals',
        event_category: 'Web Vitals',
        event_action: data.name,
        event_value: Math.round(data.name === 'CLS' ? data.delta * 1000 : data.delta),
        event_label: data.id
    });
}

function trackCWV() {
    webVitals.getCLS(sendCWVToGTM);
    webVitals.getFID(sendCWVToGTM);
    webVitals.getLCP(sendCWVToGTM);
    webVitals.getFCP(sendCWVToGTM);
    webVitals.getTTFB(sendCWVToGTM); 
}

(function() {
    INSTAW.stickyToTop.init();
    INSTAW.utils.openPopupOnClick();
    INSTAW.utils.magnific();
    INSTAW.utils.animationedSections();
    INSTAW.utils.hashAnchorClick();

    INSTAW.copyText();
    INSTAW.voting();
    INSTAW.cookieNotification.init();
    INSTAW.utils.trackEvent();

    INSTAW.utils.inputLabels();
    trackCWV();
}());

window.addEventListener('scroll', function() {
    INSTAW.menu.navbarPosition();
}, false);